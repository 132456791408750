
@import "../../../../styles/variables.scss";

.preslam-header {
  text-align: center;
}

.preslam-input-container {
  margin: auto;
}

.preslam-results{
  display: block;
  margin: auto;
  width: 80%;
}


