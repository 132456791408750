@import "../../../styles/variables.scss";

.tool-links{
    width:88%;
    margin: 2.5% 6%;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: #fff;

    &-data{
      display: flex;
      flex-wrap: wrap;
      padding: 1px;
      gap: 12px 3%;
      align-items: center;
      justify-content: center;
      margin:0px
    }
}

.section-header{
  text-align: center;
}