.ofis-input-container{
  margin: auto;
  max-width: 1200px;
}
.ofis-workflow{     
  display: flex;
  flex-direction: row;
  padding: 0 2%;
  gap: 4%;
  flex-wrap: wrap;
  // Tablet view
  @media(max-width:900px){
    display:grid;
    grid-template-areas: 'A A' 'B C'; 
    padding-bottom: 2%;
  }
  //Phone view
  @media (max-width:500px){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.ofis-input{
  width:42%;
  //Tablet view
  @media(max-width:900px){
    padding: 0 5%;
    grid-area: A;
    width:100%;
  }
  //Phone view
  @media (max-width:500px){
    width: 100%;
  }
 
}

.ofis-radio{
  width:22%;
  //Tablet view
  @media(max-width:900px){
    padding-left: 12%;
    width: 100%;
    grid-area: B;
  }
  //Phone view
  @media (max-width:500px){
    padding: 4% 0 4% 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

}

.ofis-drop-down{
  width:20%;
   //Tablet view
  @media(max-width:900px){
    width: 12rem;
    grid-area: C;
  }
   //Phone view
  @media (max-width:500px){
    width: 90%;
    font-size:small;
  } 
}

.ofis-header{
  text-align: center;
}
.ofis-table-layout{
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 40px 0;
  display: block;
  margin: auto;
  width: 80%;
}

.table-render1 {
  table {
    td {
      border: 1px solid #d8d8d8;
      padding: 0px;
      text-align: left;
    }
  }
}