.container {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
  }

@media (max-width: 767px) {
    // In mobile show video below description
  .container {
    flex-direction: column;
    }
  }
  
.user_guide_summary_text {
    flex: 0.4; /* 30% width */
    padding: 5;
    margin-top: 5%;
  }
  
.user_guide_video {
    flex: 0.6;
    padding: 10px;
  }
  
.user_guide_video video {
    max-width: 100%;
    height: auto;
  }