.mfn-input-container{
  margin: auto;
}
.mfn-header{
  text-align: center;
}
.mfn-package-search-results{
  display: block;
  margin: auto;
  width: 80%;
}