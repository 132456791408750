
.inspection-search-results{
    display: block;
    margin: auto;
    width: 75%;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 1%;
}

.table-render {
    table {
      td {
        border-right: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        padding-left: 10px;
        padding-top: 1px;
        padding-right: 1px;
        padding-bottom: 1px;
        text-align: left;
      }
      td:last-child {
        border-right: none;
      }
    }
  }