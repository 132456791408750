@import "../../../styles/variables.scss";
@import "@amzn/meridian-tokens/base/color";

nav{
    color: $primary-font-color !important;
    font-size: $font-size-main;
    margin: 0px;
}

.logo {
    width: 100px; 
}

.user-section {
    color: white;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.user-profile-icon {
    margin-left: 0.5rem;
    border-radius: 50%;
    height: 35px;
}
