@import "@amzn/meridian-tokens/base/color";
@import "@amzn/meridian-tokens/base/font/amazon-ember.css";

// font weight
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-heading-color: #077398;
$min-width-l: 1280px;
// Main Font
$font-size-main: 14px;

// Z-Index variables



// Spacing variables


// Color variables
$primary-theme-color: #045C69; // Meridian palette teal-600
$primary-font-color: #ffffff;
$nav-font-color: #ffffff;
$nav-menu-color: #000000;

.section-header{
    font-family: "Amazon Ember";
    color: $primary-theme-color !important;
    font-size: 14px;
    font-weight: 600 !important;
  }

