.radio-util > :first-child{
    // Mobile view
    @media(max-width:500px){
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    }
    @media(max-width:400px){
        gap: 0.2rem;
    }
}