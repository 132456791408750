.afn-input-container {
  margin: auto;
}

.afn-header {
  text-align: center;
}

.recent-searches-button {
  background-color: white;
}

.afn-search-component {
  display: grid;
  grid-gap: 5px;
}

.afn-search-results {
  display: block;
  margin: auto;
  width: 80%;
}

.package-search-results {
  display: block;
  margin: auto;
  width: 80%;
}

.rate_limit_alert {
  justify-content: 'center';
  position: 'fixed';
  bottom: '6.5%';
  left: 0;
  right: 0
}

.table-render {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.table-render * {
  font-family: inherit;
}

.table-render {
  table {
    td {
      border-right: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      padding-left: 10px;
      padding-top: 1px;
      padding-right: 1px;
      padding-bottom: 1px;
      text-align: left;
    }

    td:last-child {
      border-right: none;
    }
  }
}

.section-header1 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  text-align: left;
  color: #045C69 !important;
  font-size: 12px;
  font-weight: 600 !important;
  padding-left: 1px;
}

.cell-data {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  text-align: left;
  padding-left: 10px;
}


.checkbox-data {
  p {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: bold;
    align-items: center;
  }
}

.checkbox-data * {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  align-items: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

p.restrict-data {
  margin: 0px;
}

.afn-drop-down-field {
  width: 7rem;

  @media (max-width:750px) {
    width: 6.8rem;
  }
}

.afn-input-field {
  width: 7rem;
  padding-left: 0.7rem;

  @media (max-width:750px) {
    padding-left: 1rem;
    text-align: center;
    width: 6.8rem;
  }
}

.afn-text-area {
  width: 60%;

  @media (max-width:1000px) {
    width: 55%;
  }

  @media (max-width:750px) {
    width: 94%;
  }
}

.afn-small-width {
  @media (max-width:750px) {
    padding-left: 25%;
  }

  @media (max-width:500px) {
    padding-left: 10%;
  }
}

.bs_toggle {
  transform: translate(30px, -20px);
}

@media only screen and (max-width: 600px) {

  // Makes bulk search toggle looks aligned in mobile view
  .bs_toggle {
    padding-top: 20px;
  }
}

.table-render-item {
  table {
    td {
      border-right: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      padding-left: 10px;
      padding-top: 1px;
      padding-right: 1px;
      padding-bottom: 1px;
      text-align: left;
    }

    td:last-child {
      border-right: none;
    }
  }
}