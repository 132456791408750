@import "../../../styles/variables.scss";

.footer{
    background-color: $primary-theme-color ;
    display: flex;
    gap: 1%;
    padding: 0.5% 2%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.eagle-eye{
    align-self: flex-start;
    margin-left: 0;
}

.links-icon{
    margin: 5px;   
}