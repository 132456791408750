@import "../../../styles/variables.scss";

.columnHeader{
  font-family: "Amazon Ember";
  color: $font-heading-color;
  font-size: 12px;
  font-weight: 600;
}

/* Applying appropriate styling for line breaks */
span {
  white-space: pre-line;
}