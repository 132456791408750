@import "../../../styles/variables.scss";

.details-renderer{
  width: 88%;
  margin: 0.5% 6%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;

  &-header{
    text-align: center;
  }

  &-data{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding-bottom: 4px;
    padding-left: 0.5%;
    font-size: 12px;

  }
}

[class$="-cell"]>div{
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid #d8d8d8;
}

.details-renderer-1{
  display: grid;
  grid-template-columns: auto;
}


